import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ color }) => (
  <svg width="100%" viewBox="0 0 1500 283">
    <g>
      <path
        fill={color}
        d="M0,282.421875 L0,0 L140.625,0 C232.943945,0 292.96875,52.5066021 292.96875,138.28125 C292.96875,224.744168 231.353884,282.421875 139.453125,282.421875 L0,282.421875 Z M72.65625,230.859375 L134.765625,230.859375 C189.666863,230.859375 220.3125,192.130462 220.3125,138.28125 C220.3125,84.7275996 188.870873,50.390625 134.765625,50.390625 L72.65625,50.390625 L72.65625,230.859375 Z M407.8125,282.421875 L335.15625,282.421875 L335.15625,0 L407.8125,0 L407.8125,282.421875 Z M551.953125,282.421875 L438.28125,0 L516.796875,0 L584.765625,186.328125 C588.984971,200.479754 591.761093,210.026408 594.140625,220.3125 C596.520157,210.026408 598.89969,200.479754 603.515625,186.328125 L671.484375,0 L750,0 L636.328125,282.421875 L551.953125,282.421875 Z M856.640625,282.421875 L783.984375,282.421875 L783.984375,0 L856.640625,0 L856.640625,282.421875 Z M919.921875,282.421875 L919.921875,0 L998.4375,0 L1115.625,161.71875 C1123.37932,173.033121 1130.56017,185.761994 1135.54688,195.703125 L1136.71875,195.703125 L1136.71875,0 L1201.17188,0 L1201.17188,282.421875 L1127.34375,282.421875 L1005.46875,119.53125 C998.113364,107.797645 990.134641,95.068772 985.546875,85.546875 L984.375,85.546875 C985.347407,97.4554357 985.347407,109.786532 984.375,123.046875 L984.375,282.421875 L919.921875,282.421875 Z M1337.10938,229.6875 L1500,229.6875 L1500,282.421875 L1265.625,282.421875 L1265.625,0 L1497.65625,0 L1497.65625,52.734375 L1337.10938,52.734375 L1337.10938,113.671875 L1490.625,113.671875 L1490.625,162.890625 L1337.10938,162.890625 L1337.10938,229.6875 Z"
      />
    </g>
  </svg>
);

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
