import hoistStatics from 'hoist-non-react-statics';
import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import { display as styledSystemDisplay } from 'styled-system';

const mapProps = map => Component =>
  hoistStatics(props => <Component {...map(props)} />, Component);

const Base = styled(Box)(styledSystemDisplay);

const Hide = mapProps(
  ({ xsmall, small, medium, large, xlarge, display, ...props }) => ({
    display,
    ...props,
  }),
)(Base);

export default Hide;
