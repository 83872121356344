import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import iconAncreUp from '../assets/images/icon_ancre_up.svg';
import iconAncreUpWhite from '../assets/images/icon_ancre_up_white.svg';
import Hide from './Hide';
import Logo from './Logo';

const LINKS = [
  {
    name: 'MANAGEMENT',
    to: '/',
  },
  {
    name: 'PUBLISHING/A&R',
    to: '/publishing',
  },
  {
    name: 'BOOKING',
    to: '/booking',
  },
  {
    name: 'EVENT',
    to: '/event/',
  },
  {
    name: 'INFORMATION',
    to: '/information/',
  },
];

const NavLink = styled(Link)`
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 0.1s ease-in-out;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (min-width: 78em) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &.active,
  &.focus,
  &:hover {
    text-decoration-color: ${(props) =>
      props.invertColor ? 'white' : 'black'};
  }
`;

const RightText = styled(Text)`
  align-items: center;
  display: flex;
`;

class Footer extends PureComponent {
  render() {
    const { activePageName, invertColor } = this.props;

    return (
      <Box
        css={{
          maxWidth: '1500px',
        }}
        key={2}
        mt={[75, 75, 115]}
        mx="auto"
        px={[20, 20, 70]}
      >
        <Hide
          alignItems="center"
          as={Flex}
          css={{
            borderTop: `1px solid ${invertColor ? 'white' : 'black'}`,
          }}
          display={['none', 'none', 'none', 'none', 'flex']}
          pt={[20, 20, 70]}
          justifyContent="space-between"
        >
          <nav>
            <Flex>
              {LINKS.map((link) => (
                <NavLink
                  activeClassName="active"
                  invertColor={invertColor}
                  key={link.name}
                  to={link.to}
                >
                  <Text
                    color={invertColor ? 'white' : 'black'}
                    fontFamily="sans"
                    fontSize={['14px', '14px', '17px']}
                    letterSpacing={[0, 0, '0.5px']}
                    lineHeight={['14px', '14px', '20px']}
                  >
                    {link.name}
                  </Text>
                </NavLink>
              ))}
            </Flex>
          </nav>
          <Box
            as="a"
            css={{
              '&:hover': {
                textDecorationColor: invertColor ? 'white' : 'black',
              },
              backgroundColor: 'transparent',
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationColor: 'transparent',
              textDecorationThickness: '2px',
              textUnderlineOffset: '3px',
              transition: 'all 0.1s ease-in-out',
            }}
            href="#top"
            p={0}
          >
            <RightText
              color={invertColor ? 'white' : 'black'}
              fontFamily="sans"
              fontSize={['14px', '14px', '17px']}
              letterSpacing={[0, 0, '0.5px']}
              lineHeight={['14px', '14px', '20px']}
            >
              TOP
              <Image
                height={7}
                ml={2}
                src={invertColor ? iconAncreUpWhite : iconAncreUp}
              />
            </RightText>
          </Box>
        </Hide>
        <Hide
          display={['block', 'block', 'block', 'block', 'none']}
          pt={[25, 30, 70]}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              color={invertColor ? 'white' : 'black'}
              fontFamily="sans"
              fontSize={['14px', '14px', '17px']}
              letterSpacing={[0, 0, '0.5px']}
              lineHeight={['14px', '14px', '20px']}
            >
              {activePageName}
            </Text>
            <Box
              as="a"
              css={{
                '&:hover': {
                  textDecorationColor: invertColor ? 'white' : 'black',
                },
                backgroundColor: 'transparent',
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: 'transparent',
                textDecorationThickness: '2px',
                textUnderlineOffset: '3px',
                transition: 'all 0.1s ease-in-out',
              }}
              href="#top"
              p={0}
            >
              <Text
                color={invertColor ? 'white' : 'black'}
                css={{ alignItems: 'center', display: 'flex' }}
                fontFamily="sans"
                fontSize={['14px', '14px', '17px']}
                letterSpacing={[0, 0, '0.5px']}
                lineHeight={['14px', '14px', '20px']}
              >
                TOP
                <Image
                  height={[6, 6, 7]}
                  ml={2}
                  src={invertColor ? iconAncreUpWhite : iconAncreUp}
                />
              </Text>
            </Box>
          </Flex>
        </Hide>
        <Box pb={[25, 60]} mt={[25, 30, 70]}>
          <Logo color={invertColor ? '#ffffff' : '#000000'} />
        </Box>
      </Box>
    );
  }
}

Footer.propTypes = {
  activePageName: PropTypes.string.isRequired,
  invertColor: PropTypes.bool,
};

Footer.defaultProps = {
  invertColor: false,
};

export default Footer;
