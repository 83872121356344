import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import styled from 'styled-components';
import iconAncreDown from '../assets/images/icon_ancre_down.svg';
import iconAncreDownWhite from '../assets/images/icon_ancre_down_white.svg';
import iconMenu from '../assets/images/icon_menu.svg';
import iconMenuClose from '../assets/images/icon_menu_close.svg';
import iconMenuCloseWhite from '../assets/images/icon_menu_close_white.svg';
import iconMenuWhite from '../assets/images/icon_menu_white.svg';
import Hide from './Hide';
import Logo from './Logo';
import { Fixed, Relative } from './Position';

const LINKS = [
  {
    name: 'MANAGEMENT',
    to: '/',
  },
  {
    name: 'PUBLISHING/A&R',
    to: '/publishing',
  },
  {
    name: 'BOOKING',
    to: '/booking',
  },
  {
    name: 'EVENT',
    to: '/event/',
  },
  {
    name: 'INFORMATION',
    to: '/information/',
  },
];

const NavLink = styled(Link)`
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 0.1s ease-in-out;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (min-width: 78em) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &.active,
  &.focus,
  &:hover {
    text-decoration-color: ${(props) =>
      props.invertColor ? 'white' : 'black'};
  }
`;

const MobileNavLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:first-child {
    margin-top: 30px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const RightText = styled(Text)`
  align-items: center;
  display: flex;
`;

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  openMenu = () => {
    this.setState({
      isMenuOpen: true,
    });
  };

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  render() {
    const { activePageName, invertColor, rightText } = this.props;
    const { isMenuOpen } = this.state;

    return [
      isMenuOpen && (
        <Fixed
          css={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            height: '100%',
            width: '100%',
            zIndex: 10,
          }}
          key={0}
          left={0}
          top={0}
        />
      ),
      isMenuOpen && (
        <Relative
          as={Box}
          css={{
            backgroundColor: invertColor ? 'black' : 'white',
            maxWidth: '1500px',
            zIndex: 11,
          }}
          key={1}
          mx="auto"
          px={[20, 30, 70]}
          py={[25, 30, 70]}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              color={invertColor ? 'white' : 'black'}
              fontFamily="sans"
              fontSize={['14px', '14px', '17px']}
              letterSpacing={[0, 0, '0.5px']}
              lineHeight={['14px', '14px', '20px']}
            >
              {activePageName}
            </Text>
            <Box
              as="button"
              css={{
                backgroundColor: 'transparent',
                border: 0,
                cursor: 'pointer',
              }}
              onClick={this.closeMenu}
              p={0}
            >
              <Text
                color={invertColor ? 'white' : 'black'}
                css={{ alignItems: 'center', display: 'flex' }}
                fontFamily="sans"
                fontSize={['14px', '14px', '17px']}
                letterSpacing={[0, 0, '0.5px']}
                lineHeight={['14px', '14px', '20px']}
              >
                CLOSE
                <Flex ml={2}>
                  <Image
                    height={[14, 14, 20]}
                    width={[14, 14, 20]}
                    src={invertColor ? iconMenuCloseWhite : iconMenuClose}
                  />
                </Flex>
              </Text>
            </Box>
          </Flex>
          <nav>
            {LINKS.filter((link) => link.name !== activePageName).map(
              (link) => (
                <MobileNavLink
                  activeClassName="active"
                  key={link.name}
                  to={link.to}
                >
                  <Text
                    color={invertColor ? 'white' : 'black'}
                    fontFamily="sans"
                    fontSize={['14px', '14px', '17px']}
                    letterSpacing={[0, 0, '0.5px']}
                    lineHeight={['14px', '14px', '20px']}
                  >
                    {link.name}
                  </Text>
                </MobileNavLink>
              ),
            )}
          </nav>
        </Relative>
      ),
      <Box
        css={{
          maxWidth: '1500px',
        }}
        key={2}
        mx="auto"
        px={[20, 30, 70]}
        pt={[25, 30, 70]}
      >
        <Hide
          alignItems="center"
          as={Flex}
          display={['none', 'none', 'none', 'none', 'flex']}
          justifyContent="space-between"
        >
          <nav>
            <Flex>
              {LINKS.map((link) => (
                <NavLink
                  activeClassName="active"
                  invertColor={invertColor}
                  key={link.name}
                  to={link.to}
                >
                  <Text
                    color={invertColor ? 'white' : 'black'}
                    fontFamily="sans"
                    fontSize={['14px', '14px', '17px']}
                    letterSpacing={[0, 0, '0.5px']}
                    lineHeight={['14px', '14px', '20px']}
                  >
                    {link.name}
                  </Text>
                </NavLink>
              ))}
            </Flex>
          </nav>
          <Box
            as="a"
            css={{
              '&:hover': {
                textDecorationColor: invertColor ? 'white' : 'black',
              },
              backgroundColor: 'transparent',
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationColor: 'transparent',
              textDecorationThickness: '2px',
              textUnderlineOffset: '3px',
              transition: 'all 0.1s ease-in-out',
            }}
            href="#content"
            p={0}
          >
            <RightText
              color={invertColor ? 'white' : 'black'}
              fontFamily="sans"
              fontSize={['14px', '14px', '17px']}
              letterSpacing={[0, 0, '0.5px']}
              lineHeight={['14px', '14px', '20px']}
            >
              {rightText}
              <Flex ml={2}>
                <Image
                  height={7}
                  src={invertColor ? iconAncreDownWhite : iconAncreDown}
                />
              </Flex>
            </RightText>
          </Box>
        </Hide>
        <Hide display={['block', 'block', 'block', 'block', 'none']}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              color={invertColor ? 'white' : 'black'}
              fontFamily="sans"
              fontSize={['14px', '14px', '17px']}
              letterSpacing={[0, 0, '0.5px']}
              lineHeight={['14px', '14px', '20px']}
            >
              {activePageName}
            </Text>
            <Box
              as="button"
              css={{
                backgroundColor: 'transparent',
                border: 0,
                cursor: 'pointer',
              }}
              onClick={this.openMenu}
              p={0}
            >
              <Text
                color={invertColor ? 'white' : 'black'}
                css={{ alignItems: 'center', display: 'flex' }}
                fontFamily="sans"
                fontSize={['14px', '14px', '17px']}
                letterSpacing={[0, 0, '0.5px']}
                lineHeight={['14px', '14px', '20px']}
              >
                MENU
                <Flex ml={2}>
                  <Image
                    height={20}
                    width={20}
                    src={invertColor ? iconMenuWhite : iconMenu}
                  />
                </Flex>
              </Text>
            </Box>
          </Flex>
        </Hide>
        <Box mt={[25, 30, 70]}>
          <Logo color={invertColor ? '#ffffff' : '#000000'} />
        </Box>
      </Box>,
    ];
  }
}

Header.propTypes = {
  activePageName: PropTypes.string.isRequired,
  invertColor: PropTypes.bool,
  rightText: PropTypes.string.isRequired,
};

Header.defaultProps = {
  invertColor: false,
};

export default Header;
