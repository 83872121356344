import PropTypes from 'prop-types';
import React from 'react';
import InViewMonitor from 'react-inview-monitor';

const AnimatedContainer = ({ children }) => (
  <InViewMonitor
    classNameInView="animated"
    classNameNotInView="animated animated-hidden"
    intoViewMargin="0%"
  >
    {children}
  </InViewMonitor>
);

AnimatedContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnimatedContainer;
